import React from 'react';
import NavMenu from './menu'
import PlaceForm from '../placeForm/placeForm';
import logo from '../../images/btsexpress-logo.png'
import './header.scss';

export default class header extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {lflag: false};
    }

    lClick = () => {
        const {lflag} = this.state;
        this.setState( {lflag: !lflag} )
    }

    render () {
        const {current} = this.props;
        const {lflag} = this.state;
        const loginClass = lflag ? 'bts-login loginOpen' : 'bts-login';
        return (
            <header className="header">
                <div className="logo"><a href="/"><img src={logo} className="logo_img" alt="logo" /></a></div>
                <NavMenu current={current} navClick={this.props.navClick} />
                <div className="account" onClick={this.lClick}>Sign In</div>
                <div className={loginClass}>
                    <PlaceForm formData={loginForm} />
                </div>   
            </header>
        );
    }  

}

const loginForm = {title: "login",
                   intro: "Login to Create New Order, Schedule Delivery and create Waybill", 
                   links: {url:"https://secure.ontime360.com/sites/Btscourier/NewAccount.aspx", label: "New User Sign Up"},
                   action:"https://secure.ontime360.com/sites/Btscourier/login.aspx",
                   method: "post",
                   fields: [
                       {id: "UserName", name: "UserName", label: "User Name", type: "text", maxLength:30, value: ""},
                       {id: "Password", name: "Password", label: "Password", type: "password", maxLength:30, value: ""},
                   ]
                  }


