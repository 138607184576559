import React from 'react';
import './placeForm.scss';

export default class placeForm extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render () {
        const {formData} = this.props
        return (
            <form className="btsForm" method={formData.method} action={formData.action} target="_blank" rel="noopener noreferrer">
                <div className={"bts_form " + formData.title}>
                    {formData.intro} 
                    {Array.isArray(formData.fields) && formData.fields.length>0 && formData.fields.map( field => {
                      return <div key={field.id} className={"bts-form-field "+ field.type}>
                        {field.type==='hidden' ? <input type={field.type} id={field.id} name={field.name} value={field.value} /> : (
                               field.type==='textarea' ? <textarea placeholder={field.label} id={field.id} name={field.name} maxLength={field.maxLength} /> :
                               <input placeholder={field.label} type={field.type} id={field.id} name={field.name} maxLength={field.maxLength} />
                            )
                        }
                             </div>
                  })}
                  <div className="buttons">
                    <input className="btn" type="submit" value="Submit" />
                    <input className="btn" type="reset" value="Reset" />
                  </div>  
                  {formData.links && <div className="Links"><a href={formData.links.url} target="_blank" rel="noopener noreferrer">{formData.links.label}</a></div>}
                </div>
            </form>
        );
    }  

}