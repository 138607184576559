import React from 'react';
import PlaceForm from '../../components/placeForm/placeForm';
import railImg from '../../images/rail-h.jpg';
import roadImg from '../../images/road-h.jpg';
import oceanImg from '../../images/ocean-h.jpg';
import airImg from '../../images/air-h.jpg';
import about0Img from '../../images/about-0.jpg';
import about1Img from '../../images/about-1.jpg';
import about2Img from '../../images/about-2.jpg';
import about3Img from '../../images/about-3.jpg';
// import TrackingForm from '../../components/placeForm/trackingNumberForm';
import './contentPage.scss';

export default class contentPage extends React.Component  {
    render () {
        const{pageIndex} = this.props;
        return (
            <div className="main-page content-page">
                {contents[pageIndex]}
            </div>
        );
    }  
}
const trackingForm = {title: "tracking",
                action:"https://www.ontime360.com/tracking/trackingresults.aspx",
                method: "get",
                intro: <div className="intro">Tracking your shipment by Tracking Number, Reference Number or Order Number here. For advanced tracking and more services, please <a className="button" href="https://secure.ontime360.com/sites/Btscourier/login.aspx" target="_blank" rel="noopener noreferrer">Log In</a> to your account.</div>,
                fields: [
                   {id: "accid", name: "accid", label: "accid", type: "hidden", maxLength:50, value: "t5w6%2B5UpNG0Cou6u04YruYtmr2uT9FfKHmIIHrdS3tATC94%2BbAmi8g%3D%3D"}, 
                   {id: "mitn", name: "mitn", label: "mitn", type: "hidden", maxLength:50, value: "1"}, 
                   {id: "motn", name: "motn", label: "mitn", type: "hidden", maxLength:50, value: "1"}, 
                   {id: "TrackingNumber", name: "tn", label: "Tracking, Reference or Order Number", type: "text", maxLength:50, value: ""},
                ]
               };  
const contents = {
    'tracking' : <div className="tracking">
                <h2>Tracking Your Shipment</h2>
                <PlaceForm formData={trackingForm} />
              </div>,
    'about' : <div className="about">
                <div>
                    <img src={about0Img} alt="" className="feature-img" />
                    <h2>Helping Your Business Build Success</h2>
                    <h3>Develop Your Path to Greatness</h3>
                    <p>At Amazing Business Consulting, our business is helping your business succeed. Whether you need help with business planning, need back office support or need to change up your game to higher-level executive action, we’ve got programs and services you need to make your business rock.</p>
                    <p>Founded by Bradley Jessup in 1995, we’ve had over 20 years’ experience in helping entrepreneurs develop success in their business and personal lives. Our focus is finding areas where your business could be more productive and successful and then helping you develop the path to get it there.</p>
                </div>
                <div className="aboutList">
                    <div>
                        <img src={about1Img} alt="" />
                        <h3>Professional Services</h3>
                        <p>We offer a variety of professional services that help you achieve your business goals. Do you need help with building a comprehensive business plan to get financing? What about one-on-one mentoring to take your career to the next level? We’ve got that.</p>
                    </div>
                    <div>
                        <img src={about2Img} alt="" />
                        <h3>Comprehensive Programs</h3>
                        <p>Our dynamic programs cover businesses in a variety of stages and sizes. Whether you’re just starting out, have too many hats as a SME owner, need high-level executive mentoring or need marketing tips that fit your schedule, our programs fit the bill.</p>
                    </div>
                    <div>
                        <img src={about3Img} alt="" />
                        <h3>Amazing Results</h3>
                        <p>Where do we deliver the most? In our client’s results. We’ve worked with companies from single-person startups to Fortune 500 executives and everything in between. We provide a guarantee that if you follow our plan and don’t see amazing results, you don’t pay.</p>
                    </div>
                </div>    
              </div>,
    'service' : <div className="service">
                    <h2>Our Services</h2>
                    <div>
                        <img src={railImg} alt="" />
                        <h2>Rail freight specialist</h2>
                        <p>Rail Freight is fast, reliable and sustainable with the added benefit of quicker transit times compared to ocean transport. Transport costs are lower than airfreight, providing a viable, cost effective alternative to other more traditional methods of moving freight around the world.</p>
                        <p>We offer our own rail transport shuttle from several places in China to Hamburg and Duisburg in Germany and to Tilburg and Rotterdam in The Netherlands. Customers can use this transport solution if they find air freight too expensive and when ocean freight is not fast enough. We are able to transport your goods within 19 to 24 days from China to Germany and Rotterdam by rail.</p>
                        <p>What if you are located in another European country? With our distribution network, we offer door to door services from collected mainland China to addresses throughout Europe, making sure that your cargo will be delivered at your door as quickly as possible.</p>
                        <h3>Why rail freight with BTS Express?</h3>
                        <ul>
                            <li>Own rail transport shuttle</li>
                            <li>Door to door services</li>
                            <li>Reliable and sustainable</li>
                        </ul>        
                    </div>
                    <div>
                        <img src={roadImg} alt="" />
                        <h2>Road Distribution</h2>
                        <p>Our in house transportation department operates an own fleet of trucks and trailers. We offer daily FTL and LTL services from and / or to any country in Europe and beyond.</p>
                        <p>From 1 Kg shipments to multiple full loads, we handle your shipments using carefully selected equipment and partners.</p>
                        <p>Also intermodal transportation if offered by barge, rail or short-sea. Our contracts with the intermodal carriers ensure fixed departures and arrivals against competitive conditions.</p>
                        <p>Try our road freight services which include:</p>
                        <ul>
                            <li>FTL (Full truck Load)</li>
                            <li>LTL (Less than truck Load)</li>
                            <li>European Distribution</li>
                            <li>Express and Courier Service</li>
                            <li>Exceptional and Project Cargo</li>
                        </ul>
                        <h3>Why distribution with BTS Express?</h3>
                        <ul>
                            <li>Own fleet of trucks and trailers</li>
                            <li>FTL and LTL</li>
                            <li>Competitive rates</li>
                        </ul>
                    </div>
                    <div>
                        <img src={oceanImg} alt="" />
                        <h2>Ocean Freight</h2>
                        <p>The most cost-effective way of moving goods</p>
                        <p>Ocean freight plays a very important role in most transportation and supply chain solutions. Next to it being time-definite it also is the most cost-effective way of international transportation.</p>
                        <p>We provide reliable, cost-effective ocean freight services to and from all major ports in the world. We leverage volume commitments and contracts with various carriers to ensure flexible sailing schedules, multiple departure and arrival options against the most competitive rates.</p>
                        <p>Through our online freight management system, MyBTS, we offer 24/7 visibility of your shipments from point of origin until final destination.</p>
                        <p>Our ocean freight services include:</p>
                        <ul>
                            <li>FCL (Full Container Load)</li>
                            <li>LCL (Less than Container Load)</li>
                            <li>Licensed NVOCC and OTI (Ocean Transport Intermediary) with the FMC for USA shipments</li>
                            <li>Licensed Non-bonded Freight Forwarder with the CBSA for Canadian shipments</li>
                            <li>Buyers / Sellers Consolidations</li>
                            <li>Projects and Break-bulk shipments</li>
                            <li>Cross trades (third country to third country)</li>
                            <li>Door to Door transportation</li>
                        </ul>
                        <h3>Why ocean freight with BTS ?</h3>
                        <ul>
                            <li>Ocean freight experts</li>
                            <li>Fast transit times</li>
                            <li>Competitive rates</li>
                        </ul>
                    </div>
                    <div>
                        <img src={airImg} alt="" />
                        <h2>Air Freight</h2>
                        <p>Flying across the globe</p>
                        <p>As a specialist in global air freight forwarding, BTS offers tailor made transportation solutions for time-sensitive shipments via Air.</p>
                        <p>Our agreements with global carriers allow us to optimize capacity on your behalf. We offer you a choice of express, standard or deferred services. Our extensive network of agents puts us well positioned to handle any size or weight shipment around the globe.</p>
                        <p>We are an IATA certified air freight forwarder with AEO certification.</p>
                        <p>Our online MyBTS portal provides 24/7 tracking and tracing of your shipments.</p>
                        <p>Our air freight scope of services includes:</p>
                        <ul>
                            <li>Standard, Express or Deferred Service</li>
                            <li>Consolidation Service</li>
                            <li>Sea / Air</li>
                            <li>Projects and Chartering</li>
                            <li>Door to Door Transportation</li>
                        </ul>
                        <h3>Why air freight with BTS Logistics?</h3>
                        <ul>
                            <li>Tailor made solutions</li>
                            <li>Projects and Chartering</li>
                            <li>IATA Certified</li>
                        </ul>
                    </div>
                </div>
};

   
                