import React from 'react';
import PlaceForm from '../../components/placeForm/placeForm';
//import TrackingForm from '../../components/placeForm/trackingNumberForm';
import './mainPage.scss';
import railImg from '../../images/rail-h.jpg';
import roadImg from '../../images/road-h.jpg';
import oceanImg from '../../images/ocean-h.jpg';
import airImg from '../../images/air-h.jpg';
export default class mainPage extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {buttonIndex: ""};
    }

    render () {
        const{buttonIndex} = this.state;
        return (
            <div className="main-page home-page">
                <div className="content main">
                    <div className="siteIntro">
                        <h2>BTS Express</h2>
                        <p>We have been providing efficient and cost-effective shipping and delivery services to our customers for over a decade. We proud ourselves in reliability and customer satisfaction. Call us today and let us serve your shipping needs, large or small, anywhere in Canada!</p>
                    </div>
                    <div><PlaceForm formData={trackingForm} /></div>
                    <div className="buttons">
                        <div className={buttonIndex==="location"?"button current":"button"} onClick={()=>this.setState({buttonIndex: buttonIndex==="location"?"":"location"})}>LOCATION</div>
                        <div className={buttonIndex==="priceForm"?"button current":"button"} onClick={()=>this.setState({buttonIndex: buttonIndex==="priceForm"?"":"priceForm"})}>ESTIMATE</div>
                    </div>
                </div>
                <div className="popup">
                    {buttonIndex==="priceForm" && <PlaceForm formData={priceForm} />}
                    {buttonIndex==="location" && <iframe title="location" src="https://www.google.com/maps/d/embed?mid=1xZcUu7Aty1GVYoqmC23nYP4r8nTe9zQJ" width="100%" height="450"></iframe>}
                </div>
                <div className="content business">
                    <div>
                        <h3>Expedited Road</h3>
                        <p><strong>BTS Express</strong> complete coverage throughout Canada allows us to be extremely cost-competitive. BTS Express is among the fastest growing Expedited carriers in the country, utilizing specialized equipment, Satellite tracking and the latest technologies to provide consistent on-time service.</p>
                        <p><strong>To have BTS Express start fulfilling your specific<br /> Expedited needs contact us:<br /></strong><a href="mailto:expeditedroad@btsxpress.com">expeditedroad@btsxpress.com</a></p>
                    </div>
                    <div>
                        <h3>Intermodal</h3>
                        <p>Utilizing BTS Express Premium Rail Service provides consistent delivery to Western Canada at a fraction of your expedited cost. You benefit from our ground breaking agreement which puts your product “first on first off”, thus accelerating delivery of your products to your clients. Offering complete LTL and FTL cross Canada coverage, with an abundance of 20’, 40’, 53’ and 53’ heated containers.</p>
                        <p><strong>To have BTS Express start fulfilling your specific<br /> Expedited needs contact us:<br /></strong><a href="mailto:premiumintermodal@btsxpress.com">premiumintermodal@btsxpress.com</a></p>
                    </div>
                    <div>
                        <h3>Warehousing and Consolidation</h3>
                        <p><strong>BTS Express</strong> offers coast-to-coast consolidation services. Consolidations streamline the receiving of products into your distribution network. Whether you’re buying from five or five hundred vendors,BTS Express  proven process allows you to realize lower costs, reduced inventories, improved transit times and time-definite delivery. BTS Express can customize a consolidation program tailored for your unique requirements.</p>
                        <p><strong>To find out how you can start saving contact:<br /></strong><a href="mailto:consolidation@btsxpress.com">consolidation@btsxpress.com</a></p>
                    </div>
                </div>
                <div className="content services">
                    <h2>Our Services</h2>
                    <div>
                        <img src={railImg} alt="" />
                        <h3>Rail freight specialist</h3>
                        <p>Rail Freight is fast, reliable and sustainable with the added benefit of quicker ... ... <span className="link" onClick={()=>this.props.navClick('service')}>Read More</span> </p>
                    </div>
                    <div>
                        <img src={roadImg} alt="" />
                        <h3>Road Distribution</h3>
                        <p>Our in house transportation department operates an own fleet of trucks and trailers ... ... <span className="link" onClick={()=>this.props.navClick('service')}>Read More</span> </p>
                    </div>
                    <div>
                        <img src={oceanImg} alt="" />
                        <h3>Ocean Freight</h3>
                        <p>The most cost-effective way of moving goods ... ... <span className="link" onClick={()=>this.props.navClick('service')}>Read More</span></p>
                    </div>
                    <div>
                        <img src={airImg} alt="" />
                        <h3>Air Freight</h3>
                        <p>Flying across the global ... ... <span className="link" onClick={()=>this.props.navClick('service')}>Read More</span></p>
                    </div>
                </div>    
            </div>
        );
    }  
}

const priceForm = {title: "price",
                 action:"https://www.ontime360.com/tracking/estimateresults.aspx",
                 fields: [
                    {id: "accid", name: "accid", type: "hidden", maxLength:50,  value: "t5w6+5UpNG0Cou6u04YruYtmr2uT9FfKHmIIHrdS3tATC94+bAmi8g=="},
                    {id: "ps", name: "ps", type: "hidden", maxLength:50, value: "91bcba8d-8dfd-4c0a-9979-98e32aea6f33"},
                    {id: "pt", name: "pt", type: "hidden", maxLength:50, value: "0"},
                    {id: "dt", name: "dt", type: "hidden", maxLength:50, value: "mi"},
                    {id: "disclaimer", name: "disclaimer", maxLength:50, type: "hidden",  value: "Prices displayed here are subject to validation."},
                    {id: "OriginPostalCode", name: "OriginPostalCode", label: "Origin Zip/Postal Code",  type: "text", maxLength:25, value: ""},
                    {id: "DestinationPostalCode", name: "DestinationPostalCode", label: "Destination Zip/Postal Code",  type: "text", maxLength:25, value: ""}
                 ]
                }   
const trackingForm = {title: "tracking",
                        action:"https://www.ontime360.com/tracking/trackingresults.aspx",
                        method: "get",
                        intro: <div className="intro">Tracking your shipment by Tracking Number, Reference Number or Order Number here. For advanced tracking and more services, please <a className="button" href="https://secure.ontime360.com/sites/Btscourier/login.aspx" target="_blank" rel="noopener noreferrer">Log In</a> to your account.</div>,
                        fields: [
                            {id: "accid", name: "accid", label: "accid", type: "hidden", maxLength:50, value: "t5w6%2B5UpNG0Cou6u04YruYtmr2uT9FfKHmIIHrdS3tATC94%2BbAmi8g%3D%3D"}, 
                            {id: "mitn", name: "mitn", label: "mitn", type: "hidden", maxLength:50, value: "1"}, 
                            {id: "motn", name: "motn", label: "mitn", type: "hidden", maxLength:50, value: "1"}, 
                            {id: "TrackingNumber", name: "tn", label: "Tracking, Reference or Order Number", type: "text", maxLength:50, value: ""},
                        ]
                    }                                 