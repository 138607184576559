import React from 'react';
import './menu.scss';

export default class menu extends React.Component  {
    constructor(props) {
        super(props);
        this.state = { mmflag: false };
    }

    menuClick = () => {
        const {mmflag} = this.state;
        this.setState( {mmflag: !mmflag} )
    }
    render () {
        const {current} = this.props;
        const {mmflag} = this.state;
        const menuListClass = mmflag ? 'menuList mobileOpen' : 'menuList';
        return (
            <div className="navMenu">
                <div className="menuIcon" onClick={this.menuClick}></div>
                <ul className={menuListClass}>
                    <li className={current === "home" ?'current':'menuItem'} onClick={() => this.props.navClick('home')}>Home</li>
                    <li className={current === "tracking" ?'current':'menuItem'} onClick={() => this.props.navClick('tracking')}>Tracking</li>
                    <li className={current === "service" ?'current':'menuItem'} onClick={() => this.props.navClick('service')}>Our Services</li>
                    <li className={current === "about" ?'current':'menuItem'} onClick={() => this.props.navClick('about')}>About Us</li>
                    <li className={current === "contact"  ?'current':'menuItem'} onClick={() => this.props.navClick('contact')}>Contact Us</li>
                </ul>  
            </div>
        );
    }  

}