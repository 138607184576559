import React from 'react';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/header/header';
import MainPage from './pages/mainPage/mainPage';
import ContentPage from './pages/contentPage/contentPage';
import ContactPage from './pages/contact/contact';
import './App.scss';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {pageIndex: "home"};
  }
  changePage = (page) => {
    this.setState({pageIndex: page})
  }
  render() {
    const {pageIndex} = this.state;
    return (
      <div className="bts-app">
        <Header current={pageIndex} navClick={this.changePage} />
          {pageIndex === 'home' && <MainPage navClick={this.changePage} /> }
          {pageIndex === 'tracking' && <ContentPage pageIndex='tracking' />}
          {pageIndex === 'service' && <ContentPage pageIndex='service'/>}
          {pageIndex === 'about' && <ContentPage pageIndex='about'/>}
          {pageIndex === 'contact' &&  <ContactPage />}
        <footer className="footer">
          <div className="copyRight">&copy; 2019 BTS Express International Inc.</div>
        </footer> 
      </div>
    )
  }
}  
