import React from 'react';
//import ReactContactForm  from 'react-mail-form';
import './contact.scss';

export default class contact extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: '',
            inputMessage: '',
            inputName: '',
            inputSubject: '',
            isCaptchaValid: false,
            isErrorShown: false,
            isFormValid: false
        }
    }

        // Handle visitor's interaction with inputs
    handleInput = event => {
         // Test for input and length of the value
         if (event.target.value.length > 0) {
            this.setState({
                [event.target.name]: event.target.value
            });
         }
     }

     // Show message in console when reCaptcha plugin is loaded
     onCaptchaLoad = () => {
         console.log('Captcha loaded')
     }

     // Update state after reCaptcha validates visitor
     onCaptchaVerify = (response) => {
         this.setState({
         isCaptchaValid: true
         })
     }

     handleFormSubmit = event => {
         event.preventDefault()
         const {inputName, inputSubject, inputMessage, inputEmail} = this.state;
         // Test
         const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         if (reg.test(String(inputEmail).toLowerCase()) && inputName.length > 0 && inputSubject.length > 0 && inputMessage.length > 0) {
           /*this.setState({
             isErrorShown: false,
             isFormValid: true
           })*/
           // Reset state after sending the form
           this.setState({
            inputEmail: '',
            inputMessage: '',
            inputName: '',
            inputSubject: '',
            isCaptchaValid: false,
            isErrorShown: false,
            isFormValid: false
          });
    
          window.location.assign("mailto:info@btslogistic.com?subject=" + encodeURI(inputSubject) + "&body=" + encodeURI(inputMessage + "\n\n" + inputName));
           
         } else {
           // Show error message
           this.setState({
             isErrorShown: true
           })
         }
       }

    render () {
        const {inputName, inputSubject, inputMessage, inputEmail} = this.state;
        return (
            <div className="main-page contact-page">
                <h2>Contact Us</h2>
                <div className="address">200 Oakdale Road, Toronto, Ontario, Canada</div>
                <div className="contact-info">
                    <div>PHONE: 416-564-1345</div>
                    <div>EMAIL: info@btslogistic.com</div>
                </div>
                <div className="bts_form contact">
                    <div className="intro">Please use the contact form below, if you have any general questions or requests about our services. We will try our best to respond back to you within 24 hours.</div> 
                    <div className="bts-form-field text">
                        <input placeholder="Your Name" type="text" value={inputName} onChange={this.handleInput} name="inputName" maxLength="25" required={true} />
                    </div>
                    <div className="bts-form-field text">
                        <input placeholder="Email" type="email" value={inputEmail} onChange={this.handleInput} name="inputEmail" maxLength="25" required={true} />
                    </div>
                    <div className="bts-form-field text">
                        <input placeholder="Subject" type="text" value={inputSubject} onChange={this.handleInput} name="inputSubject" maxLength="50" required={true} />
                    </div>
                    <div className="bts-form-field textarea">
        <textarea placeholder="Your Request" onChange={this.handleInput} name="inputMessage"  required={true} maxLength="300" value={inputMessage} />
                    </div>
                    
                    {this.state.isFormSubmitted && (
                        <div className="bts-form-field success">
                            Thank you for contacting us. we will reply within 24 hours.
                        </div>
                    )}

                    {this.state.isErrorShown && (
                        <div className="bts-form-field error">
                            Please make sure to fill all fields valid.
                        </div>
                    )}
                    <div className="buttons">
                        <div onClick={this.handleFormSubmit}>Submit</div>
                    </div>  
                </div>
            </div>
        );
    }  
}